<template>
  <div class="px-[20px] flex items-center relative" :style="{ height: `${windowHeight-240}px` }">
    <div
      class="w-full chat-mess rounded-lg h-[98%] bg-ems-tag1 relative"
    >
      <TopChat :is-show-action="true" :isShowConnect="false"/>
      <div class="h-[0.5px] w-full bg-ems-gray700"></div>
      <MiddleChat :activeKey="activeKey"/>
      <BottomChat />
    </div>
  </div>
</template>

<script setup>
import { ref, computed,onMounted,defineProps,watch } from 'vue';
import TopChat from './components/top-chat.vue';
import MiddleChat from './components/middle-chat.vue';
import BottomChat from './components/bottom-chat.vue';
import VueTypes from 'vue-types';
import {useWindowHeight} from '@/util/common-utils';
import { useStore } from 'vuex';
const { state } = useStore();
const itemValue = computed(() => state.coordinator.itemValue || '');
const { windowHeight } = useWindowHeight();
const props = defineProps({
  activeKey: VueTypes.string.def(''),
});
</script>
<style lang="scss">
.chat-mess .ant-input-affix-wrapper > input.ant-input {
  background: #2B2A3A;
  color: #FFFFFF;
}
.chat-mess .ant-input-affix-wrapper > input.ant-input::placeholder {
  color: #F7F0F7;
}
</style>
